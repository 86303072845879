
import { createBrowserRouter,  Navigate } from 'react-router-dom';
import Root from './routes/root/root';
import StatisticalInfo from './routes/statistical-info/statistical-info';
import Categories from './routes/categories/categories';
import Aspects from './routes/aspects/aspects';
import Regions, { regionsLoader } from './routes/regions/regions';
import Home from './routes/home/home';
import About from './routes/about/about';
import Prices from './routes/prices/prices';
import ProtectedRoute from './components/ProtectedRoute';
import LoginPage from './components/login/login';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        children: [
            {
                path: '/',
                element: <About />, 
            },
            {
                path: '/home',
                element: <Home />,
            },
            {
                path: '/intro',
                element: (
                    <ProtectedRoute>
                        <StatisticalInfo />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/categories',
                element: (
                    <ProtectedRoute>
                        <Categories />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/aspects',
                element: (
                    <ProtectedRoute>
                        <Aspects />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/regions',
                element: (
                    <ProtectedRoute>
                        <Regions />
                    </ProtectedRoute>
                ),
                loader: regionsLoader
            },
            // {
            //     path: '/prices',
            //     element: (
            //         <ProtectedRoute>
            //             <Prices />
            //         </ProtectedRoute>
            //     ),
            // },
            {
                path: '/login',
                element: <LoginPage />,
            },
            {
                path: '*',
                element: <Navigate to="/" />,
            }
      ],
    },
  ]);

export default router;