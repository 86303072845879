import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import ChartTooltip from '../chart-tooltip/chart-tooltip.js';
import DownloadAsPNGButton from '../download-png-btn/download-as-png-btn.js';
import DownloadAsExcelButton from '../download-as-excel-btn/download-as-excel-btn.js';
import { formatFilters } from '../../utils/format-filters.js';

import './multy-bar-chart.css';

const BarChart = (props) => {
  const {
    id,
    data,
    xTickTotated,
    height,
    color,
    colorScale: customColorScale,
    fileName,
    columns,
    filters,
    title,
  } = props;

  const [tooltipTop, setTooltipTop] = useState('auto');
  const [tooltipLeft, setTooltipLeft] = useState('auto');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState('');
  const [tooltipValue, setTooltipValue] = useState('');

  const [width, setWidth] = useState(0);

  let margin = { top: 12, right: 0, bottom: xTickTotated ? 76 : 28, left: 48 },
    svgHeight = (height || 200) - margin.bottom - margin.top;

  const defaultColorScale = d3
    .scaleOrdinal()
    .domain(['Positive', 'Reviews', 'Negative'])
    .range(['#6FCF97', 'rgba(255,255,255,.2)', '#EB5757']);

  const colorScale = customColorScale || defaultColorScale;

  function initChart() {
    let initialWidth =
        document.getElementById(`chart-${id}`).getBoundingClientRect().width -
        margin.left -
        margin.right,
      svg = d3
        .select(`#chart-${id}`)
        .append('svg')
        .attr('width', initialWidth + margin.left)
        .attr('height', svgHeight + margin.bottom + margin.top)
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);

    // Create a separate legend div
    const legendContainer = d3
      .select(`#chart-${id}`)
      .append('div')
      .attr('class', 'legend-container')
      .style('display', 'flex')
      .style('flex-direction', 'row')
      .style('gap', '20px')
      .style('margin-top', '5px')
      .style('justify-content', 'center');

    // Populate the legend
    colorScale.domain().forEach((d) => {
      const legendItem = legendContainer
        .append('div')
        .style('display', 'flex')
        .style('align-items', 'center')
        .style('margin-bottom', '5px'); // Space between legend items

      legendItem
        .append('div')
        .style('width', '10px')
        .style('height', '10px')
        .style('background-color', colorScale(d))
        .style('margin-right', '5px'); // Space between box and text

      legendItem
        .append('span')
        .text(d)
        .style('font-size', '12px')
        .style('color', '#fff');
    });

    svg
      .append('g')
      .attr('transform', 'translate(0,' + svgHeight + ')')
      .attr('class', 'x-axis');

    svg
      .append('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(0, 0)`);

    setWidth(initialWidth);
  }

  function updateChart() {
    if (!data) return;
    let svg = d3.select(`#chart-${id} svg g`);

    const subgroups = data.columns.slice(1);

    const groups = data.map((d) => d.group);

    const x = d3.scaleBand().domain(groups).range([0, width]).padding(0.2);

    const y = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(data, function (d) {
          return d3.max(Object.values(d).filter((v) => typeof v === 'number'));
        }),
      ])
      .range([svgHeight, 0]);

    const xSubgroup = d3
      .scaleBand()
      .domain(subgroups)
      .range([0, 41])
      .padding([0.05]);

    const colorScale = d3
      .scaleOrdinal()
      .domain(subgroups)
      .range(color || ['#6FCF97', 'rgba(255,255,255,.2)', '#EB5757']);

    function mouseover(e, d) {
      svg.selectAll('.bars rect').attr('fill-opacity', 0.6);
      this.setAttribute('fill-opacity', 1);
      setTooltipTitle(d.key);
      setTooltipValue(d.value);
      setTooltipVisible(true);
    }

    function mousemove(e) {
      setTooltipTop(e.offsetY + margin.top + 'px');
      setTooltipLeft(e.offsetX + 'px');
    }

    function mouseleave(d) {
      svg.selectAll('.bars rect').attr('fill-opacity', 1);
      setTooltipVisible(false);
    }

    svg.selectAll('.x-axis').transition().duration(1000).call(d3.axisBottom(x));

    if (xTickTotated) {
      svg
        .selectAll('.x-axis text')
        .attr('transform', 'rotate(-40)')
        .attr('text-anchor', 'end')
        .attr('dx', '-.4em')
        .attr('dy', '.1em');
    }

    svg
      .selectAll('.y-axis')
      .transition()
      .duration(1000)
      .call(d3.axisLeft(y).ticks(5));

    svg
      .selectAll('.y-axis path, .y-axis line, .x-axis path, .x-axis line')
      .remove();

    svg.selectAll('.tick text').attr('class', 'chart_tick_text');
    svg
      .selectAll('.bars')
      // Enter in data = loop group per group
      .data(data)
      .join('g')
      .attr('class', 'bars')
      .attr('transform', (d) => `translate(${x(d.group)}, 0)`)
      .selectAll('rect')
      .data(function (d) {
        return subgroups.map(function (key) {
          return { key: key, value: d[key] };
        });
      })
      .join('rect')
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseout', mouseleave)
      .attr('x', (d) => xSubgroup(d.key) + x.bandwidth() / 2 - 20)
      .attr('y', (d) => y(d.value))
      .attr('rx', () => {
        return 6;
      })
      .transition()
      .duration(1000)
      .attr('width', 12)
      .attr('height', (d) => svgHeight - y(d.value))
      .attr('fill', (d) => colorScale(d.key));
  }

  useEffect(() => {
    initChart();
  }, []);

  useEffect(() => {
    updateChart();
  }, [data]);

  return (
    <div className="chart-wrapper">
      <div className="chart-container" id={`chart-${id}`}>
        <ChartTooltip
          top={tooltipTop}
          left={tooltipLeft}
          title={tooltipTitle}
          visible={tooltipVisible}
          value={tooltipValue}
        />
      </div>
      <div className="download-btns">
        <DownloadAsExcelButton
          data={data}
          columns={columns}
          fileName={fileName}
        />
        <DownloadAsPNGButton
          chartId={`chart-${id}`}
          fileName="bar-chart.png"
          metadata={{
            title: title,
            filters: formatFilters(filters),
            downloadDate: new Date().toLocaleDateString(),
            websiteName: 'Zanzibar AI for Tourism',
          }}
        />
      </div>
    </div>
  );
};

BarChart.propTypes = {};

BarChart.defaultProps = {};

export default BarChart;
