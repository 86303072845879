import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import ChartTooltip from '../chart-tooltip/chart-tooltip.js';
import DownloadAsPNGButton from '../download-png-btn/download-as-png-btn.js';
import DownloadAsExcelButton from '../download-as-excel-btn/download-as-excel-btn.js';
import { formatFilters } from '../../utils/format-filters.js';

import './map.css';

const Map = (props) => {
  let {
      id,
      data,
      geodata,
      click,
      getValueFunction,
      getColorsFunction,
      getTooltipTextFunction,
      fileName,
      columns,
      filters,
      title,
    } = props,
    [tooltipTop, setTooltipTop] = useState('auto'),
    [tooltipLeft, setTooltipLeft] = useState('auto'),
    [tooltipVisible, setTooltipVisible] = useState(false),
    [tooltipTitle, setTooltipTitle] = useState(''),
    [tooltipValue, setTooltipValue] = useState(''),
    margin = { top: 20, right: 20, bottom: 20, left: 20 };

  function initChart() {
    const width =
      document.getElementById(`chart-${id}`).getBoundingClientRect().width -
      margin.left -
      margin.right;
    const height = window.innerHeight - 120 - margin.top - margin.bottom;
    let svg = d3
      .select(`#chart-${id}`)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.bottom + margin.top)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    function mouseover(e, d) {
      setTooltipTitle(d.properties.shapeName);
      setTooltipValue(d.total);
      this.style.stroke = 'black';
      setTooltipVisible(true);
    }

    function mousemove(e) {
      setTooltipTop(e.offsetY + 'px');
      setTooltipLeft(e.offsetX + 'px');
    }

    function mouseleave(d) {
      this.style.stroke = 'none';
      setTooltipVisible(false);
    }

    let projection = d3.geoNaturalEarth1().fitSize([width, height], geodata);
    svg
      .append('g')
      .selectAll('path')
      .data(geodata.features)
      .join('path')
      .attr('d', d3.geoPath().projection(projection))
      .on('click', (e, d) => {
        if (click) {
          click(e, d);
        }
      })
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseout', mouseleave)
      .attr('fill', 'none');
  }
  function updateChart() {
    const colors = getColorsFunction(data);
    const colorScale = d3
      .scaleThreshold()
      .domain(colors.trashold)
      .range(colors.range);
    d3.selectAll(`#chart-${id} path`).attr('fill', function (d) {
      const value = getValueFunction(data[d.properties.shapeName]);
      const tooltipText = getTooltipTextFunction(data[d.properties.shapeName]);
      d.total = tooltipText || 0;
      return colorScale(value || 0);
    });
  }

  useEffect(initChart, []);

  useEffect(updateChart, [data]);

  return (
    <div className="chart-wrapper">
      <div className="chart-container" id={`chart-${id}`}>
        <ChartTooltip
          top={tooltipTop}
          left={tooltipLeft}
          title={tooltipTitle}
          visible={tooltipVisible}
          value={tooltipValue}
        />
      </div>
      <div className="download-btns">
        <DownloadAsExcelButton
          data={data}
          columns={columns}
          fileName={fileName}
        />
        <DownloadAsPNGButton
          chartId={`chart-${id}`}
          fileName="map.png"
          metadata={{
            title: title,
            filters: formatFilters(filters),
            downloadDate: new Date().toLocaleDateString(),
            websiteName: 'Zanzibar AI for Tourism',
          }}
        />
      </div>
    </div>
  );
};

Map.propTypes = {};

Map.defaultProps = {};

export default Map;
