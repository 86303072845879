import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import ChartTooltip from '../chart-tooltip/chart-tooltip.js';
import DownloadAsPNGButton from '../download-png-btn/download-as-png-btn.js';
import { downloadDataAsExcel } from '../../utils/download-as-excel.js';
import { FaDownload } from 'react-icons/fa6';
import { formatFilters } from '../../utils/format-filters.js';

import './pie-chart.css';

const PieChart = (props) => {
  let { id, data, hasLegend, legendRight, height, filters, title } = props;

  const [tooltipTop, setTooltipTop] = useState('auto');
  const [tooltipLeft, setTooltipLeft] = useState('auto');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState('');
  const [tooltipValue, setTooltipValue] = useState('');
  const colors = [
      '#AF8EF0',
      '#6FCF97',
      '#E6B65E',
      '#53b1fd',
      '#93a7ff',
      '#cf98fa',
      '#ff86de',
      '#ff79b3',
      '#ff7b81',
      '#ff8d4c',
      '#ffa600',
      '#AF8EF0',
    ],
    colorsScale = d3.scaleOrdinal().range(colors),
    pie = d3.pie().value((d) => d[1]);

  function initChart() {
    let size =
      height ||
      document.getElementById(`chart-${id}`).getBoundingClientRect().width;
    d3.select(`#chart-${id}`)
      .append('svg')
      .attr('width', size)
      .attr('height', size)
      .append('g')
      .attr('transform', `translate(${size / 2},${size / 2})`);
  }

  function updateChart() {
    let size =
      height ||
      document.getElementById(`chart-${id}`).getBoundingClientRect().width;
    const svg = d3.select(`#chart-${id} svg g`),
      processedData = pie(Object.entries(data).sort((a, b) => b[1] - a[1]));

    function mouseover(e, d) {
      svg.selectAll('.pie-piece').attr('fill-opacity', 0.6);
      this.setAttribute('fill-opacity', 1);
      setTooltipTitle(d.data[0]);
      setTooltipValue(d.data[1]);
      setTooltipVisible(true);
    }

    function mousemove(e) {
      setTooltipTop(e.offsetY + 'px');
      setTooltipLeft(e.offsetX + 'px');
    }

    function mouseleave(d) {
      svg.selectAll('.pie-piece').attr('fill-opacity', 1);
      setTooltipVisible(false);
    }

    svg
      .selectAll('.pie-piece')
      .data(processedData)
      .join('path')
      .attr('class', 'pie-piece')
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseout', mouseleave)
      .transition()
      .duration(1000)
      .attr(
        'd',
        d3
          .arc()
          .innerRadius(size / 2 - 50)
          .outerRadius(size / 2)
      )
      .attr('fill', (d) => colorsScale(d.data[0]));
  }

  function computePercentage(n) {
    const vol = Object.values(data).reduce((a, b) => a + b, 0);
    return ((n / vol) * 100).toFixed(1);
  }

  useEffect(initChart, []);

  useEffect(updateChart, [data]);

  const handleDownloadExcel = () => {
    const chartData = Object.entries(data).map(([key, value]) => ({
      Category: key,
      Value: value,
      Percentage: `${computePercentage(value)}%`,
    }));
    const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
    const filename = `distribution-of-types_${timestamp}.xlsx`;
    downloadDataAsExcel(filename, chartData);
  };

  return (
    <div className="chart-wrapper">
      <div
        id={`chart-with-legend-${id}`}
        className={`${legendRight ? 'pie-chart-one-line' : ''}`}
      >
        <div className="chart-container" id={`chart-${id}`}>
          <ChartTooltip
            top={tooltipTop}
            left={tooltipLeft}
            title={tooltipTitle}
            visible={tooltipVisible}
            value={tooltipValue}
          />
        </div>
        {hasLegend && (
          <div className="pie-chart-legend">
            {Object.entries(data)
              .sort((a, b) => b[1] - a[1])
              .map((d, i) => {
                return (
                  d[1] !== 0 && (
                    <div key={i} className="legend-item">
                      <div
                        className="legend-color"
                        style={{ backgroundColor: colors[i % colors.length] }}
                      ></div>
                      <div className="legend-text">
                        {d[0]} ({`${computePercentage(d[1])}%`})
                      </div>
                    </div>
                  )
                );
              })}
          </div>
        )}
      </div>
      <div className="download-btns">
        <button onClick={handleDownloadExcel} className="download-btn">
          <FaDownload color="white" />
        </button>
        <DownloadAsPNGButton
          chartId={`chart-with-legend-${id}`}
          fileName="pie-chart.png"
          metadata={{
            title: title,
            filters: formatFilters(filters),
            downloadDate: new Date().toLocaleDateString(),
            websiteName: 'Zanzibar AI for Tourism',
          }}
        />
      </div>
    </div>
  );
};

PieChart.propTypes = {};

PieChart.defaultProps = {};

export default PieChart;
