import React, { useContext } from 'react';
import { Col, Row, Space } from 'antd';
import Highlights from '../../components/highlights/highlights';
import Platforms from '../../components/platforms/platforms';
import Summary from '../../components/summary/summary';
import StatInfoChart from '../../components/stat-info-chart/stat-info-chart';
import Section from '../../components/section/section';
import RegionDestribution from '../../components/region-destribution/region-destribution';
import DistributionOverTime from '../../components/distribution-over-time/distribution-over-time';
import BusinessTypeDistribution from '../../components/business-type-distribution/business-type-distribution';
import SeasonsRegionsGenderStats from '../../components/seasons-regions-gender-stats/seasons-regions-gender-stats';
import Context from '../../context/context';
import AppliedFilters from '../../components/applied-filters/applied-filters';
import PageTitle from '../../components/page-title/page-title';

import './statistical-info.css';

const StatisticalInfo = (props) => {
  const { filteredReviews } = useContext(Context);

  console.log('filteredReviews', filteredReviews);

  return (
    <>
      <Row>
        <Col span={24}>
          <PageTitle title="Statistical Information" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AppliedFilters />
        </Col>
      </Row>
      <Section title="Overview">
        <Space size={16} direction="vertical" style={{ display: 'flex' }}>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Row className="full-height" gutter={[24, 24]}>
                <Col span={12}>
                  <StatInfoChart
                    className="full-height"
                    title="# of Scraped Reviews "
                    value={filteredReviews}
                    dimension="# of reviews"
                    id="scraped-reviews"
                  />
                </Col>
                <Col span={12}>
                  <StatInfoChart
                    className="full-height"
                    title="# of Analyzed Reviews "
                    value={filteredReviews.filter((r) => r.analyzed)}
                    id="analyzed-reviews"
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row className="full-height" gutter={[24, 24]}>
                <Col span={12}>
                  <Platforms className="full-height" />
                </Col>
                <Col span={12}>
                  <Highlights className="full-height" />
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row>
            <Col span={24}>
              <Summary />
            </Col>
          </Row> */}
        </Space>
      </Section>
      <DistributionOverTime />
      <RegionDestribution />
      <BusinessTypeDistribution />
      <SeasonsRegionsGenderStats />
    </>
  );
};

StatisticalInfo.propTypes = {};

StatisticalInfo.defaultProps = {};

export default StatisticalInfo;
