import React from 'react';
import { Link } from 'react-router-dom';
import './about.css';

import { GoGlobe } from 'react-icons/go';
import { GoDatabase } from 'react-icons/go';
import { LuBrain } from 'react-icons/lu';
import { FaRegMap } from 'react-icons/fa';
import { FaArrowRight } from 'react-icons/fa6';

const About = () => {
  const featureCards = [
    {
      icon: <GoGlobe color="#fff" size={48} />,
      title: 'Real-Time Tourism Insights',
      description:
        'Capture live data on tourist sentiments, destinations, and experiences across Zanzibar.',
    },
    {
      icon: <GoDatabase color="#fff" size={48} />,
      title: 'Advanced Data Analysis',
      description:
        'Transform raw data into actionable insights for tourism businesses and policymakers.',
    },
    {
      icon: <LuBrain color="#fff" size={48} />,
      title: 'AI-Powered Predictions',
      description:
        'Leverage AI and NLP to forecast tourism trends and visitor preferences.',
    },
    {
      icon: <FaRegMap color="#fff" size={48} />,
      title: 'Geospatial Understanding',
      description:
        'Utilize Geographic Information Systems to map and analyze tourism patterns.',
    },
  ];

  return (
    <div className="mlondola-about-page">
      <div className="container">
        <header className="hero-section">
          <h1>Welcome To JozaniAI!</h1>
          <p>
            Enhancing Zanzibar's Tourism Sector Through Real-Time Data Insights
          </p>
          <div className="hero-cta">
            <Link to="/home" className="explore-button">
              Explore JozaniAI
              <FaArrowRight size={20} className="button-icon" />
            </Link>
          </div>
        </header>

        <section className="about-section">
          <h2>About JozaniAI</h2>
          <div className="about-content">
            <p>
              JozaniAI is a joint initiative between the UNDP Tanzania
              Accelerator Lab, ICPSD SDG AI Lab and the Zanzibar Commission for
              Tourism, designed to enhance the tourism sector through real-time
              data insights. Traditional reports, like the annual International
              Visitors’ Exit Survey, leave a gap in timely information. To
              bridge this, the SDG AI Lab at ICPSD provides technical support,
              focusing on data analysis and interactive visualizations.
            </p>
            <p>
              This web platform gathers real-time data from various sources to
              capture tourist sentiments, popular destinations, preferred
              accommodations, and activities in Zanzibar. By leveraging advanced
              technologies such as Artificial Intelligence (AI), Natural
              Language Processing (NLP), and Geographic Information Systems
              (GIS), tourism businesses can better understand visitor
              preferences, improve services, and boost customer satisfaction.
            </p>
            <p>
              Predictive analytics further forecast future trends based on
              historical data, helping stakeholders anticipate demand and make
              informed decisions. This innovative, open-source approach supports
              Zanzibar's tourism growth and sets a model for smarter, more
              responsive tourism management worldwide.
            </p>
          </div>
        </section>

        <section className="technologies-section">
          <h2>Key Technologies</h2>
          <div className="technologies-grid">
            {featureCards.map((card, index) => (
              <div key={index} className="technology-card">
                <div className="technology-icon">{card.icon}</div>
                <h3>{card.title}</h3>
                <p>{card.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="partners-section">
          <h2>Our Partners</h2>
          <div className="partners-logos">
            <img
              className="home-sponsors-image"
              src={require('./../../assets/sponsor1.png')}
            />
            <img
              className="home-sponsors-image"
              src={require('./../../assets/sponsor2.png')}
            />
            <img
              className="home-sponsors-image"
              src={require('./../../assets/sponsor3.png')}
            />
            <img
              className="home-sponsors-image"
              src={require('./../../assets/sdgailab_logo.png')}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;
