import React, { useState, useContext, useEffect } from 'react';
import Section from '../section/section';
import BarChart from '../bar-chart/bar-chart';
import ChartCard from '../chart-card/chart-card';
import EstablishmentTypeToggle from '../establishment-type-toggle/establishment-type-toggle';
import Context from '../../context/context';
import DownloadAsExcelButton from '../download-as-excel-btn/download-as-excel-btn';
import DownloadAsPNGButton from '../download-png-btn/download-as-png-btn';

import './region-destribution.css';

const RegionDestribution = () => {
  const {
    filteredReviews,
    selectedCategory,
    selectedPlatform,
    selectedSubCategory,
    selectedSentiment,
    selectedAspect,
    selectedRegion,
  } = useContext(Context);

  function computeBarsData() {
    const data = filteredReviews
      .filter((r) => r.district)
      .reduce((reviewAcc, review) => {
        if (reviewAcc[review.district]) {
          reviewAcc[review.district] += 1;
        } else {
          reviewAcc[review.district] = 1;
        }
        return reviewAcc;
      }, {});

    return Object.keys(data).map((key) => {
      return {
        key: key,
        value: data[key],
      };
    });
  }

  const [data, setData] = useState(computeBarsData());

  useEffect(() => {
    setData(computeBarsData());
  }, [filteredReviews]);

  const appliedFilters = {
    platform: selectedPlatform || '',
    category: selectedCategory || '',
    subcategories: selectedSubCategory || [],
    sentiment: selectedSentiment || '',
    aspects: selectedAspect || [],
    regions: selectedRegion || [],
  };

  return (
    <Section title={<span>Number of reviews over regions</span>}>
      <EstablishmentTypeToggle />
      <ChartCard>
        <BarChart
          id="regions"
          hasLegend={false}
          data={data}
          xTickTotated={true}
          columns={{ key: 'Region', value: 'Number of Reviews' }}
          fileName="region-distribution"
          filters={appliedFilters}
          title="Nember of reviews over regions"
        />
      </ChartCard>
    </Section>
  );
};

RegionDestribution.propTypes = {};

RegionDestribution.defaultProps = {};

export default RegionDestribution;
